'use client';
import styled from 'styled-components';

export const Card = styled.div`
  border-radius: 32px;
  border: 2px solid
    ${props =>
      props.checked ? props.theme.colors.secondary.egg : 'transparent'};
  display: inline-flex;
  flex-direction: column;
  user-select: none;
  overflow: hidden; /* prevent overflow on rounded corners */
  position: relative; /* allow absolute positioning of child elements */
  width: 360px;
  max-height: 110px;
  background: ${({ theme, unavailable }) =>
    unavailable
      ? theme.colors.background.disabled
      : theme.colors.background.lightest};
  opacity: ${({ $notSelectable }) => ($notSelectable ? 0.5 : 1)};
  color: ${({ theme }) => theme.colors.text.secondary};
  padding-bottom: ${props => props.theme.spacing(15.5)};
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: ${props => props.theme.spacing(1, 2)};
`;

export const StyledImage = styled.div`
  display: flex;
  align-items: center;
  border-radius: 16px;
  overflow: hidden;
  object-position: left;
  object-fit: cover;
  height: 100px;
  width: 100px;
  filter: ${props => props.unavailable && 'grayscale(100%) brightness(93%)'};
`;

export const Info = styled.div`
  width: 100%;
  max-width: 251px;
  color: ${({ theme }) => theme.colors.text.secondary};
  padding-left: ${props => props.theme.spacing(2)};
`;

export const CardHeader = styled.div`
  display: flex;
  gap: ${props => props.theme.spacing(4)};
`;

export const KeyInfo = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin: ${props => props.theme.spacing(2, 4, 0, 0)};
  gap: ${({ theme }) => theme.spacing(2)};
  width: 100%;
`;

export const Name = styled.h5`
  ${({ theme }) => theme.typography.heading5}
  text-transform: capitalize;
`;

export const PriceSymbolContainer = styled.div`
  margin-left: auto;
`;

export const PricingSymbol = styled.div``;

export const InactiveSymbol = styled.span`
  color: #cbcbcb;
`;

export const ActiveSymbol = styled.span`
  color: ${props => props.theme.colors.status.successPrimary};
`;

export const Description = styled.p`
  ${({ theme }) => theme.typography.subheading2}
  color: ${props => props.theme.colors.text.secondary};
  margin-top: ${props => props.theme.spacing(1)};
  text-transform: uppercase;
`;

export const MoreDetails = styled.button`
  ${({ theme }) => theme.typography.body}
  left: ${props => props.theme.spacing(4)};
  bottom: ${props => props.theme.spacing(8)};
  align-items: center;
  display: flex;
  border: none;
  padding: 0 3px;
  visibility: ${props =>
    props.unavailable || props.disabled ? 'hidden' : 'visible'};
  background-color: transparent;
  color: ${({ theme }) => theme.colors.hero.huckleberry};
  text-align: left;
  z-index: 1;

  svg {
    margin-left: ${props => props.theme.spacing(1)};
  }
  &:focus {
    border-radius: ${props => props.theme.spacing(2)};
    outline: 2px solid ${({ theme }) => theme.colors.neutral.darkestFur};
  }
`;
