import ollieAppDesktopImage from '../../images/account/inside-the-app-desktop-image.png';
import ollieAppMobileImage from '../../images/account/inside-the-app-mobile-image.png';
import googlePlayStoreButtonImage from '../../images/account/google-play-button.svg';
import appleStoreButtonImage from '../../images/account/apple-button.svg';
import appQRCode from '../../images/account/qr-code.svg';

export const APP_CTA_MODAL_CONTENT = {
  title: 'INSIDE THE APP',
  subtitle: "Customize your pup's meal plan and more with the Ollie app.",
  body: 'Manage your box',
  list: {
    heading: '',
    icon: 'checkmarkSimple',
    items: [
      {
        body: 'Update your delivery date',
        component: 'list_item',
        heading: '',
      },
      { body: 'Adjust your portion', component: 'list_item', heading: '' },
      { body: 'Get a Health Screening', component: 'list_item', heading: '' },
      { body: 'Track orders and more', component: 'list_item', heading: '' },
    ],
  },
  //add images
  desktopImage: {
    src: ollieAppDesktopImage,
    alt: '',
  },
  mobileImage: {
    src: ollieAppMobileImage,
    alt: '',
  },
  cta: [
    {
      component: 'social_button',
      image: {
        src: appleStoreButtonImage,
        alt: 'Download on the App Store',
      },
      href: 'https://apps.apple.com/us/app/ollie-human-grade-dog-food/id1614301164',
      openNewTab: true,
    },
    {
      component: 'social_button',
      image: {
        src: googlePlayStoreButtonImage,
        alt: 'Get it on Google Play',
      },
      href: 'https://play.google.com/store/apps/details?id=com.ollie.mobile_app',
      openNewTab: true,
    },
  ],
  qrCode: {
    src: appQRCode,
    alt: '',
  },
  qrExplanation: 'Scan code below to download the app',
};
